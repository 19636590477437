<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand
      class="mx-auto d-lg-none"
      src="img/brand/iotels-logo.png"
      width="190"
      height="46"
      alt="Iotels Logo"
    />
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>

   <CHeaderNav v-if="liveness">
     <BBadge class="mr-1" v-for="(v,k) in livenessNameMap" :key="v" :variant="liveness[k]?'success':'dark'">{{ v }}</BBadge>
   </CHeaderNav>

    <CHeaderNav>
      <CHeaderNavItem class="px-3">
        <button
          @click="() => $store.commit('toggle', 'soundOn')"
          class="c-header-nav-btn"
        >
          <BButton :variant="$store.state.soundOn?'outline-info':'secondary'">
            <CIcon v-if="$store.state.soundOn" name="cil-volume-high"/>
            <CIcon v-else name="cil-volume-off"/>
            <span class="small ml-2"><b>SOUND {{$store.state.soundOn?'ON':'OFF'}}</b></span>
          </BButton>

        </button>
      </CHeaderNavItem>

      <TheHeaderDropdownAccnt :key="accountKey"/>

      <BNavItemDropdown size="sm" id="user-nav" :text="$store.state.user.nick" class="mr-3">
        <BDropdownItem @click="clickUserInfo">정보 변경</BDropdownItem>
        <BDropdownDivider/>
        <BDropdownItem @click="clickLogout">로그아웃</BDropdownItem>
      </BNavItemDropdown>


      <CHeaderNavItem class="px-3 mr-2">
        <button
          class="c-header-nav-btn"
          :class="{ 'blink': isShowSpinner}"
          @click="$store.commit('toggle', 'asideShow')"
        >


          <CIcon size="xl" class="mr-2" name="cil-bell"/>

          <BBadge variant="danger">
            {{ $store.state.alarm.count.total }}
          </BBadge>
        </button>
      </CHeaderNavItem>

    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>

  </CHeader>
</template>


<style>
.tooltip .tooltip-inner {
  text-align: left;
  max-width: 350px !important;
  width: 250px !important;
}

@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink-effect 1s step-end infinite;

  /*
  animation-name: blink-effect;
  animation-duration: 1s;
  animation-iteration-count:infinite;
  animation-timing-function:step-end;
  */
}


</style>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import {apiCall, beepSound, clone, speech} from "@/common/utils";


export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data(){
    return {
      user: this.$store.state.user,
      accountKey: Math.floor((Math.random() * 100000)),
      timer: null,
      btnDisabled: true,
      isShowSpinner: false,
      spinnerInterval: null,
      socket: null,
      liveness: null,
      beforeLive: null,
      livenessNameMap: {}
    }

  },
  computed: {
    alarmCounter : function(){
      return this.$store.state.alarm.count.total;
    }
  },

  async created(){
    // console.log( "-------------------------- TheHeader created ------------------------ ");

    // this.isErsSound = $isErsOn;
    // this.isMsgSound = $isMsgOn;
    setTimeout( async ()=>{
      console.warn("[TheHeader] ############ register LIVENESS Handler #########");
      this.socket = this.$store.state.socket;
      if(this.socket) {
        this.socket.removeListener('LIVENESS', this.livenessHandler)
        this.socket.on('LIVENESS', this.livenessHandler);
      }
      console.warn( "[TheHeader] ############ socket object ------> is connecting? ", this.socket.connected );
      await this.getLiveness();
    }, 3000);


    // this.user = $user;
    this.accountKey = Math.floor((Math.random() * 100000));

    this.spinnerInterval = setInterval(()=>{
      this.isShowSpinner = false;
    }, 3*60*1000); // 3분 후 Off
  },

  methods: {
    clickLogout : async function(){
      await this.$store.dispatch('LOGOUT', 'U100');
      // setTimeout( async ()=>await this.$router.push({ path: '/login'  }), 500 );
    },

    clickUserInfo: function() {
      this.$router.push({ path: '/users/user-info'  });
    },

    async soundToggle(){
      console.log('soundToggle', this.$store.state.soundOn);
      await beepSound('success');
    },

    livenessHandler(data){
      this.beforLive = clone(this.liveness);
      this.liveness = data;
      Object.keys(this.beforLive).map(svr=>{
        if( this.beforLive[svr] !== this.liveness[svr] ){
          let text = this.livenessNameMap[svr]
          let mesg = this.liveness[svr]?' 정상': ' 연결 유실'
          // speech(  text + mesg );
        }
      })

      // console.log( data );
    },

    async getLiveness(){
      try {
        const r = await apiCall('get', `/api/job/liveness`);
        console.log('[TheHeader]_______________________ getLiveness __________________', r);
        if (r.code === 200){
          this.liveness = r.result.liveness;
          this.livenessNameMap = r.result.livenessName;
        }
      }catch(err){
        console.error(err);
      }

    }
  },
  watch: {
    alarmCounter() {
      // console.log('changed alarm counter ---->', v);
      this.isShowSpinner = true;
    }
  },
  beforeDestroy(){
    if(this.spinnerInterval) clearInterval( this.spinnerInterval);
    this.socket.removeListener('LIVENESS', this.livenessHandler);

  }

}
</script>
