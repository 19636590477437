<template>
  <CFooter :fixed="false">
    <div>
      <a href="http://www.iotels.me" target="_blank">Iotels</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} IoTels Co.,Ltd.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://www.iotels.me">IoTels</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
