<template>
  <CDropdown
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img small
               :src="avatarUrl"
               style="max-width:25px"
               class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" color="light">
      <strong>
        {{$store.state.user.name}}
        <BBadge :variant="($store.state.user.role==='SA')?'warning':'primary'">{{displayRole}}</BBadge>
      </strong>
    </CDropdownHeader>
    <CDropdownHeader tag="div" color="light">
      아이디 <BBadge variant="info" class="ml-1"><strong>{{user.id}}</strong></BBadge>
    </CDropdownHeader>

  </CDropdown>
</template>

<script>
const roleMap = {
  SA: '관리자 권한',
  SM: '담당자 권한',
  SS: '사용자 권한'
};

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
      user: null,
      itemsCount: 42,
      avatarUrl: null,
      displayRole: null,
    }
  },
  created(){
    let role = this.$store.state.user.role;
    this.user = this.$store.state.user;
    this.displayRole = roleMap[role];
    // switch( this.user.role ) {
    //     case 'OA': this.displayRole = '관리자'; break;
    //     case 'OM': this.displayRole = '담당자'; break;
    //     case 'OS': this.displayRole = '사용자'; break;
    //     default: this.displayRole = 'Unknown'; break;
    // }

    //this.avatarUrl = ($user.userAvatar)?$baseURL+'/file/'+$user.userAvatar: '/images/avatar-SS.png';
    this.avatarUrl = `/img/avatar/avatar-SM.png`;
    // console.log( "TheHeaderDropdownAccnt-------------------------------avatarUrl", this.avatarUrl );
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
